<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline">Dashboard</router-link>
        <span class="text-gray-400"> / </span>
        <router-link to="/commissions" class="text-green hover:underline">Commissions</router-link>
        <span class="text-gray-400"> / </span>
        <span class="font-bold">Sales order detail</span>
      </div>
    </div>
  </div>
  <div class="container mx-auto py-5" v-if="!loadingSalesOrder">
    <div class="grid grid-cols-3 xl:grid-cols-4">
      <div class="col-span-3 grid grid-cols-3">
        <div class="shadow col-span-1 bg-gray-50 text-center px-4 pt-2 pb-10">
          <div class="text-xs flex text-gray-400">
            <span >Created {{ parseDate(salesOrder.created_at) }}</span>
            <span class="text-right flex-grow">Updated {{ parseDate(salesOrder.updated_at) }}</span>
          </div>
          <span class="block text-gray-400 mt-10">SO #</span>
          <h2 class="font-bold text-3xl">{{ salesOrder.number }}</h2>
          <span class="block mt-10">Status: <span class="font-bold">{{ statusText(salesOrder.status) }}</span></span>
        </div>
        <div class="shadow ml-5 bg-gray-100 col-span-2">
          <div class="text-sm">
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Customer</span>
              <span class="w-9/12 font-bold">{{ salesOrder.customer }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Customer PO #</span>
              <span class="w-9/12">{{ salesOrder.customer_po_number }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Billing zip</span>
              <span class="w-9/12">{{ salesOrder.billing_zip }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12">Shipping zip</span>
              <span class="w-9/12">{{ salesOrder.shipping_zip }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-3/12 font-bold">Total commission</span>
              <span class="w-9/12 font-bold">${{ salesOrder.commission }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Part #</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Billing territory</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shipping territory</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Registered owner</th>
                </tr>
                </thead>
                <tbody>
                <tr :class="item.id % 2 == 0 ? 'bg-white' : 'bg-gray-100'" v-for="item in salesOrder.items" v-bind:key="item.id">
                  <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ item.description}}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ item.quantity }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${{ item.price }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${{ formatNumber(item.quantity * item.price) }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold">${{ formatNumber(item.billing_commission) }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold">${{ formatNumber(item.shipping_commission) }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-bold">${{ formatNumber(item.part_owner_commission) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div><!-- container -->
</template>

<script>
import { mapGetters } from "vuex";


export default {
  data: () => {
    return {
      loadingSalesOrder: true,
    };
  },

  computed: {
    ...mapGetters({
      salesOrder: "salesOrders/details",
      user: "user",
    }),
  },

  created: function () {
    document.title = 'MLE Rep Center | Sales order';
    this.loadSalesOrder();
  },

  methods: {
    async loadSalesOrder() {
      this.loadingSalesOrder = true;
      await this.$store.dispatch('salesOrders/loadDetails', this.$route.params.id);
      this.loadingSalesOrder = false;
      console.log(this.salesOrder)
    },

    parseDate(date) {
      let d = new Date(date);
      return d.toLocaleDateString();
    },

    statusText(status) {
      switch (status) {
        case 0:
          return "Entered";
        case 1:
          return "Picked";
        case 2:
          return "Packed";
        case 3:
          return "Shipped";
        default:
          return "Unknown";
      }
    },

    formatNumber(n) {
      return (Math.round(parseFloat(n) * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
    }
  },
};
</script>
